import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants/URL";

//GET STUDENT LIST
export const getWeeklyFiles =
  (year, gradeId, shiftId, batchId, week) => async (dispatch) => {
    try {
      const data = {
        year,
        batchId,
        gradeId,
        shiftId,
        year,
        week,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${BASE_URL}/api/weekly-file/get`,
        JSON.stringify(data),
        config
      );

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

//GET STUDENT LIST
export const getRangedWeeklyFiles =
  (year, gradeId, shiftId, batchId, start, end, uid) => async (dispatch) => {
    try {
      const data = {
        year,
        batchId,
        gradeId,
        shiftId,
        year,
        start,
        end,
        uid,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${BASE_URL}/api/weekly-file/get-ranged`,
        JSON.stringify(data),
        config
      );

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };
//GET STUDENT LIST
export const getWeeklyFileStudentList =
  (year, gradeId, shiftId, batchId, week) => async (dispatch) => {
    try {
      const data = {
        year,
        batchId,
        gradeId,
        shiftId,
        year,
        week,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${BASE_URL}/api/weekly-file/students`,
        JSON.stringify(data),
        config
      );

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

// Submit File
export const submitWeeklyFile =
  (initData, file, uid, studentId) => async (dispatch) => {
    try {
      const data = new FormData();
      if (initData.grade) {
        data.append("gradeId", initData.grade);
      } else {
        toast.error("Class not found");
        return;
      }
      if (initData.shift) {
        data.append("shiftId", initData.shift);
      } else {
        toast.error("Shift not found");
        return;
      }
      if (initData.batch) {
        data.append("batchId", initData.batch);
      } else {
        toast.error("Batch not found");
        return;
      }
      if (studentId) {
        data.append("studentId", studentId);
      } else {
        toast.error("Student ID not found");
        return;
      }
      if (initData.year) {
        data.append("year", initData.year);
      } else {
        toast.error("Year not found");
        return;
      }
      if (initData.week) {
        data.append("week", initData.week);
      } else {
        toast.error("Week not found");
        return;
      }
      if (uid) {
        data.append("uid", uid);
      } else {
        toast.error("UID not found");
        return;
      }
      if (file) {
        data.append("file", file);
      } else {
        toast.error("File not found");
        return;
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const res = await axios.post(
        `${BASE_URL}/api/weekly-file/`,
        data,
        config
      );

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

export const deleteWeeklyFile = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${BASE_URL}/api/weekly-file/${id}`);

    return res.data.data;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
