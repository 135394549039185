import { Badge, Button, Loader } from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { submitTerminalFile } from "../../actions/TerminalFile";
import { connect } from "react-redux";

const FileUpload = ({
  file,
  setFile,
  students,
  setStudents,
  data,
  submitTerminalFile,
}) => {
  const modals = useModals();
  const [loading, setLoading] = useState(false);

  const delay = () => new Promise((resolve) => setTimeout(resolve, 200));
  const confirmDelete = (id) => {
    modals.openConfirmModal({
      title: "Delete File",
      centered: true,
      children: "Are you sure you want to delete this file?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        setFile(file.filter((f) => f.name !== id));
      },
    });
  };

  const submitFileUpload = async (uid, f) => {
    if (
      students.filter((s) => `${s.uid}`.trim() === `${uid}`.trim()).length === 0
    ) {
      return false;
    }
    const res = await submitTerminalFile(
      data,
      f,
      uid,
      students.filter((s) => `${s.uid}`.trim() === `${uid}`.trim())[0].id
    );

    return res;
  };
  const submitAllFileUpload = async () => {
    setLoading(true);
    const responses = [];
    for (const fileItem of file) {
      if (
        fileItem.status === "New" &&
        students.filter(
          (s) => (s) => `${s.uid}`.trim() === `${fileItem.name}`.trim()
        ).length > 0
      ) {
        const res = await submitFileUpload(fileItem.name, fileItem.file);
        responses.push(res.uid);
        await delay();
      } else {
        continue;
      }
    }

    setFile(
      file.map((f) =>
        responses.includes(`${f.name}`.trim()) ? { ...f, status: "Saved" } : f
      )
    );
    setLoading(false);
  };
  if (!students) return <Loader />;
  return (
    <Card bg="white" text="dark" className={`crd shadow mt-3`}>
      <Card.Body>
        <h4>Uploaded Files</h4>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>UID</th>
              <th>Student Name</th>
              <th>Status</th>
              <th>File</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {file
              .sort((s1, s2) => s1.name - s2.name)
              .map((f, index) => (
                <tr key={index}>
                  <td>
                    {students.filter(
                      (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                    ).length > 0 ? (
                      <>
                        {
                          students.filter(
                            (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                          )[0].uid
                        }
                      </>
                    ) : (
                      <>
                        <span className="text-danger fw-bold">N/A</span>
                      </>
                    )}
                  </td>
                  <td>
                    {students.filter(
                      (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                    ).length > 0 ? (
                      students.filter(
                        (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                      )[0].name
                    ) : (
                      <>
                        <span className="text-danger fw-bold">
                          Student Not Found
                        </span>
                      </>
                    )}
                  </td>
                  <td>
                    {students.filter(
                      (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                    ).length > 0 &&
                    students.filter(
                      (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                    )[0].fileExists ? (
                      <Badge variant="filled" color="red">
                        Existing
                      </Badge>
                    ) : students.filter(
                        (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                      ).length === 0 ? (
                      <Badge variant="filled" color="red">
                        Error Parsing Student
                      </Badge>
                    ) : (
                      <Badge variant="filled" color="green">
                        New
                      </Badge>
                    )}
                  </td>
                  <td>{f.file.name}</td>
                  <td>
                    {students.filter(
                      (s) => `${s.uid}`.trim() === `${f.name}`.trim()
                    ).length === 0 ? (
                      <></>
                    ) : f.status === "New" ? (
                      <>
                        <Button
                          color="green"
                          size="xs"
                          onClick={async () => {
                            const res = await submitFileUpload(f.name, f.file);
                            setFile(
                              file.map((f) =>
                                `${f.name}`.trim() === res.uid
                                  ? { ...f, status: "Saved" }
                                  : f
                              )
                            );
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          color="red"
                          size="xs"
                          ml={6}
                          onClick={() => confirmDelete(f.name)}
                        >
                          Delete
                        </Button>
                      </>
                    ) : (
                      <Badge variant="filled" color="green">
                        Upload Successful
                      </Badge>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center align-ites-center">
          <Button
            disabled={
              loading || file.filter((f) => f.status === "New").length === 0
            }
            onClick={() => submitAllFileUpload()}
          >
            {loading
              ? "Uploading..."
              : file.filter((f) => f.status === "New").length === 0
              ? "All Files Uploaded"
              : "Upload All Files"}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default connect(null, { submitTerminalFile })(FileUpload);
