export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ACCESS_TOKEN_ERROR = "ACCESS_TOKEN_ERROR";
export const ACCESS_TOKEN_SUCCESS = "ACCESS_TOKEN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const DASHBOARD_DATA = "DASHBOARD_DATA";

//PROFILE REDUCER
export const AUTH_USER_LOAD = "AUTH_USER_LOAD";
export const AUTH_USER_LOAD_ERROR = "AUTH_USER_LOAD_ERROR";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

// GRADE REDUCER
export const CREATE_GRADE = "CREATE_GRADE";
export const DELETE_GRADE = "DELETE_GRADE";
export const GET_GRADE_DETAILS = "GET_GRADE_DETAILS";
export const UPDATE_GRADE = "UPDATE_GRADE";
export const GET_GRADE = "GET_GRADE";

// FEATURE REDUCER
export const CREATE_FEATURE = "CREATE_FEATURE";
export const DELETE_FEATURE = "DELETE_FEATURE";
export const GET_FEATURE_DETAILS = "GET_FEATURE_DETAILS";
export const UPDATE_FEATURE = "UPDATE_FEATURE";
export const GET_FEATURE = "GET_FEATURE";

// SUBJECT REDUCER
export const CREATE_SUBJECT = "CREATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const GET_SUBJECT_DETAILS = "GET_SUBJECT_DETAILS";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const GET_SUBJECT = "GET_SUBJECT";

// SHIFT REDUCER
export const CREATE_SHIFT = "CREATE_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const GET_SHIFT_DETAILS = "GET_SHIFT_DETAILS";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const GET_SHIFT = "GET_SHIFT";

// BATCH REDUCER
export const CREATE_BATCH = "CREATE_BATCH";
export const DELETE_BATCH = "DELETE_BATCH";
export const GET_BATCH_DETAILS = "GET_BATCH_DETAILS";
export const GET_GRADE_BATCH = "GET_GRADE_BATCH";
export const UPDATE_BATCH = "UPDATE_BATCH";
export const GET_BATCH = "GET_BATCH";

// STUDENT REDUCER
export const CREATE_STUDENT = "CREATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const GET_STUDENT = "GET_STUDENT";

// MARK REDUCER
export const CREATE_MARK = "CREATE_MARK";
export const DELETE_MARK = "DELETE_MARK";
export const GET_MARK_DETAILS = "GET_MARK_DETAILS";
export const UPDATE_MARK = "UPDATE_MARK";
export const GET_MARK = "GET_MARK";
export const GET_STUDENT_MARK = "GET_STUDENT_MARK";
export const GET_STUDENT_MARK_UID = "GET_STUDENT_MARK_UID";
export const GET_BATCH_STUDENTS = "GET_BATCH_STUDENTS";
// WEEK REDUCER
export const CREATE_WEEK = "CREATE_WEEK";
export const DELETE_WEEK = "DELETE_WEEK";
export const UPDATE_WEEK = "UPDATE_WEEK";
export const GET_WEEK = "GET_WEEK";
export const GET_WEEK_BATCH = "GET_WEEK_BATCH";
export const GET_ALL_WEEKLY = "GET_ALL_WEEKLY";
export const GET_STUDENT_WEEK = "GET_STUDENT_WEEK";
// WEEK FILE REDUCER
export const CREATE_WEEK_FILE = "CREATE_WEEK_FILE";
export const DELETE_WEEK_FILE = "DELETE_WEEK_FILE";
export const UPDATE_WEEK_FILE = "UPDATE_WEEK_FILE";
export const GET_WEEK_FILE = "GET_WEEK_FILE";
export const GET_WEEK_STUDENTS = "GET_WEEK_STUDENTS";
