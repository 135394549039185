import {
  CREATE_WEEK_FILE,
  DELETE_WEEK_FILE,
  GET_WEEK_FILE,
  GET_WEEK_STUDENTS,
} from "../constants/Type";

const initialState = {
  students: null,
  files: null,
  loading: true,
};

const weeklyFileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_WEEK_STUDENTS:
      return {
        ...state,
        student: payload,
        loading: false,
      };
    case GET_WEEK_FILE:
      return {
        ...state,
        files: payload,
        loading: false,
      };

    case DELETE_WEEK_FILE:
      return {
        ...state,
        files: state.files.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    default:
      return state;
  }
};

export default weeklyFileReducer;
