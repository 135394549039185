import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getGradeList } from "../../actions/Grade.action";
import ViewIndividualTerminalFiles from "../../components/ViewIndividualTerminalFiles/ViewIndividualTerminalFiles";
import Layout from "../../components/shared/Layout/Layout";

const ViewIndividualTerminalFilePage = ({ grades, getGradeList }) => {
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <Layout title={`Individual Terminal Files`}>
        <ViewIndividualTerminalFiles grades={grades} />
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(
  ViewIndividualTerminalFilePage
);
