import React, { useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BsUpload } from "react-icons/bs";

import { MdOutlineWarning } from "react-icons/md";
import { connect } from "react-redux";
import { getWeeklyFileStudentList } from "../../actions/WeeklyFile";
import { years } from "../../constants/MonthsAndYears";
import { weekArray } from "../../constants/WeekArray";
import FileUpload from "./FileUpload";

const AddWeeklyFilesForm = ({ grades, getWeeklyFileStudentList }) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [week, setWeek] = useState("1");
  const [students, setStudents] = useState(null);
  const [file, setFile] = useState([]);
  const ref = useRef(null);

  const selectHandeler = async () => {
    // Get all the students
    const data = await getWeeklyFileStudentList(
      year,
      grade,
      shift,
      batch,
      week
    );
    if (data !== false) {
      setStudents(data);
      if (file.length > 0) {
        setFile([]);
      }
    } else {
      setStudents(null);
    }
  };

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files); // Convert FileList to Array

    const data = uploadedFiles.map((file) => ({
      name: file.name.replace(".pdf", ""), // Parsed file name
      file: file,
      status: "New",
    }));

    setFile(data); // Update state with array of objects
  };
  return (
    <div>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="week" className="d-block">
                  Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setWeek(e.target.value);
                }}
                id="week"
                value={week}
                name="week"
              >
                {weekArray.map((item) => (
                  <option key={item} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {students ? (
        students.length > 0 ? (
          <Card bg="white" text="dark" className={`crd shadow mt-3`}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-center my-auto">
                  Found {students.length} Students
                </h3>
                <Button
                  onClick={() => {
                    ref.current.click();
                  }}
                  className="btn_primary"
                >
                  <BsUpload /> Upload Files for Week {week} of {year}
                </Button>
                <input
                  type="file"
                  ref={ref}
                  className="d-none"
                  multiple
                  onChange={handleFileUpload}
                />
              </div>
            </Card.Body>
          </Card>
        ) : (
          <h3 className="text-center text-danger py-5">
            <MdOutlineWarning /> No Student Found
          </h3>
        )
      ) : (
        <></>
      )}

      {file?.length > 0 && students && students.length > 0 ? (
        <FileUpload
          file={file}
          setFile={setFile}
          students={students}
          setStudents={setStudents}
          data={{ year, grade, shift, batch, week }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default connect(null, { getWeeklyFileStudentList })(AddWeeklyFilesForm);
