import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getGradeList } from "../../actions/Grade.action";
import Layout from "../../components/shared/Layout/Layout";
import ViewWeeklyFiles from "../../components/ViewWeeklyFiles/ViewWeeklyFiles";

const ViewWeeklyFilePage = ({ grades, getGradeList }) => {
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <Layout title={`Weekly File`}>
        <ViewWeeklyFiles grades={grades} />
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(ViewWeeklyFilePage);
