import React from "react";
import { Container, Table } from "react-bootstrap";
import styles from "./StudentView.module.css";
import Moment from "react-moment";
import SectionHeading from "../SectionHeading/SectionHeading";
import { BASE_URL } from "../../constants/URL";
import logo from "../../Assets/logo.jpeg";

const StudentView = ({ data, weekRange, response }) => {
  return (
    <Container fluid className="py-0">
      <div className="position-relative">
        {data?.student?.image ? (
          <>
            <img src={logo} alt="logo" className={styles.logo} />
            <div
              className={styles.logo2}
              style={{
                background: `url(${BASE_URL}/api/uploads/${data?.student?.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            ></div>
          </>
        ) : (
          <></>
        )}
        <SectionHeading
          noLogo={data?.student?.image}
          title={``}
          subtitle={``}
          grade={data?.student?.gradeId}
          shift={data?.student?.shiftId}
          batch={data?.student?.batchId}
        />
        <Table bordered size="sm" responsive>
          <tbody>
            <tr>
              {data && data.student && data.student.name && (
                <>
                  <td>
                    <span className="fw-bold">Name</span>
                  </td>
                  <td>{data?.student.name}</td>
                </>
              )}

              {data && data.student && data.student.phone && (
                <>
                  <td>
                    <span className="fw-bold">Phone</span>
                  </td>
                  <td>{data?.student.phone}</td>
                </>
              )}
            </tr>
            <tr>
              {data && data.student && data.student.father && (
                <>
                  <td>
                    <span className="fw-bold">Father</span>
                  </td>
                  <td>{data?.student.father}</td>
                </>
              )}
              {data && data.student && data.student.mother && (
                <>
                  <td>
                    <span className="fw-bold">Mother</span>
                  </td>
                  <td>{data?.student.mother}</td>
                </>
              )}
            </tr>
            <tr>
              {data && data.student && data.student.dob && (
                <>
                  <td>
                    <span className="fw-bold">Date of Birth</span>
                  </td>
                  <td>
                    <Moment format="DD/MM/YYYY">{data?.student.dob}</Moment>
                  </td>
                </>
              )}
              {data && data.student && data.student.blood && (
                <>
                  <td>
                    <span className="fw-bold">Blood Group</span>
                  </td>
                  <td>{data?.student.blood}</td>
                </>
              )}
            </tr>
            <tr>
              {data && data.student && data.student.address && (
                <>
                  <td>
                    <span className="fw-bold">Address</span>
                  </td>
                  <td colSpan={3}>{data?.student.address}</td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default StudentView;
