import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import styles from "./ViewWeeklyFiles.module.css";

import { connect } from "react-redux";
import { getWeeklyFiles } from "../../actions/WeeklyFile";
import { years } from "../../constants/MonthsAndYears";
import { weekArray } from "../../constants/WeekArray";
import FileList from "./FileList";

const ViewWeeklyFiles = ({ grades, getWeeklyFiles }) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [week, setWeek] = useState("1");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const selectHandeler = async () => {
    setLoading(true);
    try {
      const list = await getWeeklyFiles(year, grade, shift, batch, week);
      if (list !== false) {
        setFiles(list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <div className="d-flex justify-content-end align-items-center pb-3 ">
        <div className="text-right">
          <Link to="/admin/weekly-files/add" className={styles.add}>
            <BsFileEarmarkPlus />
          </Link>
        </div>
      </div> */}

      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  className="btn_primary"
                >
                  {loading ? "Loading..." : "Select"}
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="week" className="d-block">
                  Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setWeek(e.target.value);
                }}
                id="week"
                value={week}
                name="week"
              >
                {weekArray.map((item) => (
                  <option key={item} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {files ? <FileList files={files} setFiles={setFiles} /> : <></>}
    </div>
  );
};

export default connect(null, { getWeeklyFiles })(ViewWeeklyFiles);
