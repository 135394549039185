import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiOutlineHome,
  AiOutlineMedicineBox,
  AiOutlineSafetyCertificate,
} from "react-icons/ai";
import { BiBadgeCheck, BiBox, BiFile, BiUser } from "react-icons/bi";
import { BsFillDiagram3Fill, BsStack } from "react-icons/bs";
import { FaTags } from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";

import { FiLogOut } from "react-icons/fi";
import { GoThreeBars } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { connect } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../actions/Dashboard.action";
import styles from "./Layout.module.css";
import { MdOutlinePostAdd } from "react-icons/md";

const Layout = ({ logout, children, title }) => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const logoutHandeler = async () => {
    let check = await logout();
    if (check === true) {
      navigate("/");
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="position-relative">
          <Col
            md={2}
            className={`px-4 ${styles.wrapper} ${show ? styles.active : ""}`}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <Link
                to="/"
                className="d-flex align-items-center py-3 text-decoration-none text-dark"
              >
                {/* <img src={logo} alt="" className={styles.logo} /> */}
                {/* <span className="d-block fs-4 ">Angular Esports</span> */}
              </Link>
              <div
                className={`${styles.ham}  ms-auto`}
                onClick={() => setShow(!show)}
              >
                <GoThreeBars />
              </div>
            </div>

            <div className={styles.nav}>
              <NavLink to="/dashboard" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlineHome />
                </span>
                <span className={styles.nav__item_text}>Dashboard</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/grade" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BsFillDiagram3Fill />
                </span>
                <span className={styles.nav__item_text}>Class</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/shift" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BsStack />
                </span>
                <span className={styles.nav__item_text}>Shift</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/batch" className={styles.nav__item}>
                <span className={styles.icon}>
                  <HiOutlineUserGroup />
                </span>
                <span className={styles.nav__item_text}>Batch</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/subject" className={styles.nav__item}>
                <span className={styles.icon}>
                  <FaTags />
                </span>
                <span className={styles.nav__item_text}>Learning Matters</span>
              </NavLink>
            </div>

            <div className={styles.nav}>
              <NavLink to="/admin/student" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BiUser />
                </span>
                <span className={styles.nav__item_text}>Student</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/evaluation" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BiBadgeCheck />
                </span>
                <span className={styles.nav__item_text}>Evaluation</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                to="/admin/yearly-evaluation"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <AiOutlineSafetyCertificate />
                </span>
                <span className={styles.nav__item_text}>Yearly Evaluation</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                to="/admin/add-weekly-files"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <MdOutlinePostAdd />
                </span>
                <span className={styles.nav__item_text}>Add Weekly File</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/weekly-files" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BiFile />
                </span>
                <span className={styles.nav__item_text}>Weekly Files</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                to="/admin/ranged/weekly-files"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <BiFile />
                </span>
                <span className={styles.nav__item_text}>
                  Ranged Weekly Files
                </span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                to="/admin/add-terminal-files"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <AiOutlineMedicineBox />
                </span>
                <span className={styles.nav__item_text}>Add Terminal File</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/terminal-files" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BiBox />
                </span>
                <span className={styles.nav__item_text}>Terminal Files</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                to="/admin/individual/terminal-files"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <BiBox />
                </span>
                <span className={styles.nav__item_text}>
                  Individual Terminal Files
                </span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/links" className={styles.nav__item}>
                <span className={styles.icon}>
                  <FiGlobe />
                </span>
                <span className={styles.nav__item_text}>Landing Links</span>
              </NavLink>
            </div>

            <div className={styles.nav}>
              <div className={styles.nav__item} onClick={logoutHandeler}>
                <span className={styles.icon}>
                  <FiLogOut />
                </span>
                <span className={styles.nav__item_text}>Logout</span>
              </div>
            </div>
          </Col>
          <Col md={10} className="bg-light">
            <div className="d-flex justify-content-end align-items-center py-3">
              <div
                className={`${styles.ham}  me-auto`}
                onClick={() => setShow(!show)}
              >
                <GoThreeBars />
              </div>
              {title ? (
                <h3 className="me-auto ps-4 fs-3 my-auto">{title}</h3>
              ) : (
                <></>
              )}

              {/* <UserInfoTopbar /> */}
            </div>
            <Container>{children}</Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, { logout })(Layout);
