import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { createMark, getYearlyMark } from "../../actions/Mark.action";
import { months, years } from "../../constants/MonthsAndYears";
import { remarkArray } from "../../constants/remarksArray";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getSubName } from "../../utils/getSubName";
import StudentView from "./StudentView";
import styles from "./ViewYearlyEvaluationForm.module.css";

const ViewYearlyEvaluationForm = ({ getYearlyMark, grades }) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [uid, setUid] = useState("");
  const [remark, setRemark] = useState(remarkArray[0].name);
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);

  const [subjects, setSubjects] = useState(null);
  const ref = useRef(null);

  const [response, setResponse] = useState(null);

  const selectHandeler = async () => {
    const list = await getYearlyMark(batch, year, grade, shift, uid);
    if (list !== false) {
      let data = [];
      list.data.map((item) => {
        data.push({
          month: item.month,
          year: item.year,
          marks: [
            ...item.marks
              .sort((a, b) => a.subjectId - b.subjectId)
              .map((l) => ({
                id: l.subjectId,
                subjectId: l.subjectId,
                grade: l.grade,
              })),
          ],
          remark: item.overall,
        });
      });
      setSubjects(data);
      setResponse(list);
    } else {
      setResponse(null);
    }
  };

  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" && uid !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3 d-none d-md-block"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <InputGroup className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="uid" className="d-block">
                    Student Serial
                  </label>
                </div>
                <Form.Control
                  placeholder="Student Serial..."
                  name="uid"
                  type="number"
                  required
                  className={`${styles.input} w-100`}
                  onChange={(e) => setUid(e.target.value)}
                />
              </InputGroup>
            </Col>

            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            {grade !== "" && shift !== "" && batch !== "" && uid !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3 d-block d-md-none"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
      {subjects ? (
        <Card bg="white" text="dark" className={`crd shadow mt-4`}>
          <Card.Body className="p-0">
            <div className="p-md-4 p-0" ref={ref}>
              {response.data && response.data.length > 0 && response.student ? (
                <div className={" pt-4 pb-3"}>
                  <div className="">
                    <StudentView
                      data={response}
                      months={months}
                      response={response}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {subjects !== null ? (
                subjects.map((item) => (
                  <>
                    <h5 className="text-center pt-3">
                      {months[item.month]}, {item.year} - Evaluation
                    </h5>
                    <Table striped bordered responsive hover size="sm">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="">Learning Matters</th>
                          <th className="text-center">Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.marks
                          .sort(
                            (a, b) =>
                              parseInt(a.subjectId) - parseInt(b.subjectId)
                          )
                          .filter((student) => getSubName(grades, student.id))
                          .map((student, i) => (
                            <tr key={i}>
                              <td className="text-center">{i + 1}</td>
                              <td className="">
                                {getSubName(grades, student.id)}
                              </td>
                              <td className="text-center">
                                <div>{student.grade}</div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    <Row className="pb-4">
                      <Col md={12}>
                        <div className={`text-center f-bold `}>
                          Overall Assessment :{" "}
                          {remarkArray.filter((rm) => rm.name === item.remark)
                            .length > 0
                            ? remarkArray.filter(
                                (rm) => rm.name === item.remark
                              )[0].text
                            : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="d-flex justify-content-center align-iteems-center ">
              <div className="">
                <ReactToPrint
                  documentTitle={`Assessment`}
                  content={() => ref.current}
                >
                  <PrintContextConsumer>
                    {({ handlePrint }) =>
                      response !== null ? (
                        <Button
                          onClick={handlePrint}
                          variant="primary"
                          className="btn_primary"
                        >
                          Print
                        </Button>
                      ) : (
                        <div>
                          <h5 className="text-danger pb-5">
                            No Evaluation Found!
                          </h5>
                        </div>
                      )
                    }
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default connect(null, { createMark, getYearlyMark })(
  ViewYearlyEvaluationForm
);
