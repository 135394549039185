import React, { useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import styles from "./ViewIndividualTerminalFiles.module.css";

import { connect } from "react-redux";
import { getIndividualTerminalFiles } from "../../actions/TerminalFile";
import { years } from "../../constants/MonthsAndYears";
import { weekArray } from "../../constants/WeekArray";
import FileList from "./FileList";
import { toast } from "react-toastify";

const ViewIndividualTerminalFiles = ({
  grades,
  getIndividualTerminalFiles,
}) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [uid, setUid] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const selectHandeler = async () => {
    if (!uid) {
      toast.error("Please enter a student serial number");
      return;
    }

    setLoading(true);
    try {
      const list = await getIndividualTerminalFiles(
        year,
        grade,
        shift,
        batch,
        uid
      );
      if (list !== false) {
        setFiles(list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  className="btn_primary"
                >
                  {loading ? "Loading..." : "Select"}
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={4} className="py-3">
              <InputGroup className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center ">
                  <label htmlFor="uid" className="d-block">
                    Student Roll
                  </label>
                </div>
                <Form.Control
                  placeholder="Type roll of the student..."
                  name="uid"
                  type="number"
                  className={`${styles.input} w-100`}
                  value={uid}
                  onChange={(e) => {
                    setUid(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {files ? <FileList files={files} setFiles={setFiles} /> : <></>}
    </div>
  );
};

export default connect(null, { getIndividualTerminalFiles })(
  ViewIndividualTerminalFiles
);
